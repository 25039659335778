$(function() {

  $(".input-group.date-picker").each(function (index) {
    $(this).tempusDominus({
      display: {
        icons: {
          type: 'icons',
          time: 'bi bi-clock',
          date: 'bi bi-calendar-week',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        viewMode: 'calendar',
        components: {
          calendar: true,
          date: true,
          month: true,
          year: true,
          decades: true,
          clock: false,
          hours: false,
          minutes: false,
          seconds: false,
        }
      },
      localization: {
        locale: 'fr-FR',
        format: 'dd/MM/yyyy',
      }
    });
  });
  $(".input-group.time-picker").each(function (index) {
    $(this).tempusDominus({
      display: {
        icons: {
          type: 'icons',
          time: 'bi bi-clock',
          date: 'bi bi-calendar-week',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        viewMode: 'clock',
        components: {
          calendar: false,
          date: false,
          month: false,
          year: false,
          decades: false,
          clock: true,
          hours: true,
          minutes: true,
          seconds: false,
        }
      },
      localization: {
        locale: 'fr-FR',
        format: 'HH:mm',
      }
    });
  });
  $(".input-group.datetime-picker").each(function (index) {
    $(this).tempusDominus({
      display: {
        icons: {
          type: 'icons',
          time: 'bi bi-clock',
          date: 'bi bi-calendar-week',
          up: 'bi bi-arrow-up',
          down: 'bi bi-arrow-down',
          previous: 'bi bi-chevron-left',
          next: 'bi bi-chevron-right',
          today: 'bi bi-calendar-check',
          clear: 'bi bi-trash',
          close: 'bi bi-x',
        },
        viewMode: 'calendar',
        components: {
          calendar: true,
          date: true,
          month: true,
          year: true,
          decades: true,
          clock: true,
          hours: true,
          minutes: true,
          seconds: false,
        }
      },
      localization: {
        locale: 'fr-FR',
        format: 'dd/MM/yyyy HH:mm',
      }
    });
  });

  Ladda.bind('button[type=submit]');
});
